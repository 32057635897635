<template>
  <validation-observer
    ref="observer"
    v-slot="{ pristine, invalid, handleSubmit }"
  >
    <b-modal
      id="modal-add-client"
      v-model="showModal"
      title="Add New Organisation"
      centered
      ok-title="Submit"
      cancel-variant="outline-secondary"
      :ok-disabled="pristine || invalid"
      @hidden="onHidden"
      @ok.prevent="handleSubmit(onSubmit)"
    >
      <b-form>
        <b-row>
          <b-col md="12">
            <validation-provider
              v-slot="validationContext"
              ref="name"
              :rules="{ required: true }"
              vid="name"
              name="Organisation Name"
            >
              <b-form-group
                label="Organisation Name"
                label-for="organisation-name-input"
              >
                <b-form-input
                  id="organisation-name-input"
                  v-model="client.name"
                  placeholder="Organisation Name"
                  maxlength="255"
                  :state="
                    getValidationState(
                      validationContext
                    )
                  "
                  @change="onNameChange"
                />
                <b-form-invalid-feedback>
                  {{
                    validationContext.errors[0]
                  }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="12">
            <validation-provider
              v-slot="validationContext"
              ref="subdomain"
              :rules="{ required: true }"
              vid="subdomain"
              name="Subdomain"
            >
              <b-form-group
                label="Participant portal URL"
                label-for="client-subdomain-input"
              >
                <b-input-group
                  prepend="https://"
                  :append="BE_API_URL"
                >
                  <b-form-input
                    id="client-path-input"
                    v-model="client.path"
                    placeholder="Path"
                    maxlength="63"
                    :state="
                      getValidationState(
                        validationContext
                      )
                    "
                  />
                </b-input-group>
                <b-form-invalid-feedback
                  :state="
                    getValidationState(
                      validationContext
                    )
                  "
                >
                  {{
                    validationContext.errors[0]
                  }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </b-form>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="outline-secondary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          type="submit"
          variant="primary"
          @click="ok()"
        >
          <b-spinner
            v-if="isUpdating"
            small
          />
          <span
            v-if="isUpdating"
          > Updating...</span>
          <span v-else>Submit</span>
        </b-button>
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BForm,
  BModal,
  BFormInvalidFeedback,
  BButton,
  BSpinner
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import clientsService from "@/services/clientsService";
import {
  toFriendlyUrlPart,
  getValidationState,
  makeSuccessToast
} from "@/libs/utils";
import { makeErrorToast } from "@/libs/utils";

const BE_API_URL = process.env.VUE_APP_BE_API_URL;

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BFormInvalidFeedback,
    BForm,
    BModal,
    BButton,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      showModal: false,
      client: { name: null, path: null },
      isUpdating: false
    };
  },
  methods: {
    show() {
      this.showModal = true;
    },
    onNameChange() {
      if (!this.client.path && this.client.name) {
        this.client.path = toFriendlyUrlPart(this.client.name);
      }
    },
    onHidden() {
      this.clearForm();
    },
    clearForm() {
      this.client = { name: null, path: null };
      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
    async onSubmit() {
      try {
        this.isUpdating = true;
        await clientsService.create(this.client);
        this.showModal = false;
        this.$emit("clientAdded");
        this.$toast(makeSuccessToast("Organisation saved."));
        return true;
      } catch (e) {
        const { status, data } = e.response;
        //server may respond with vaidation errors
        if (status === 422 && data.errors) {
          this.$refs.observer.setErrors(data.errors);
        } else {
          console.log(e);
          this.$toast(makeErrorToast(e));
        }
        return false;
      } finally {
        this.isUpdating = false;
      }
    },
  },
  setup() {
    return {
      getValidationState,
      BE_API_URL
    };
  },
};
</script>
