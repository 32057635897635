var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var pristine = ref.pristine;
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('b-modal',{attrs:{"id":"modal-add-client","title":"Add New Organisation","centered":"","ok-title":"Submit","cancel-variant":"outline-secondary","ok-disabled":pristine || invalid},on:{"hidden":_vm.onHidden,"ok":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
return [_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":function($event){return cancel()}}},[_vm._v(" Cancel ")]),_c('b-button',{attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){return ok()}}},[(_vm.isUpdating)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),(_vm.isUpdating)?_c('span',[_vm._v(" Updating...")]):_c('span',[_vm._v("Submit")])],1)]}}],null,true),model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{ref:"name",attrs:{"rules":{ required: true },"vid":"name","name":"Organisation Name"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Organisation Name","label-for":"organisation-name-input"}},[_c('b-form-input',{attrs:{"id":"organisation-name-input","placeholder":"Organisation Name","maxlength":"255","state":_vm.getValidationState(
                    validationContext
                  )},on:{"change":_vm.onNameChange},model:{value:(_vm.client.name),callback:function ($$v) {_vm.$set(_vm.client, "name", $$v)},expression:"client.name"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{ref:"subdomain",attrs:{"rules":{ required: true },"vid":"subdomain","name":"Subdomain"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Participant portal URL","label-for":"client-subdomain-input"}},[_c('b-input-group',{attrs:{"prepend":"https://","append":_vm.BE_API_URL}},[_c('b-form-input',{attrs:{"id":"client-path-input","placeholder":"Path","maxlength":"63","state":_vm.getValidationState(
                      validationContext
                    )},model:{value:(_vm.client.path),callback:function ($$v) {_vm.$set(_vm.client, "path", $$v)},expression:"client.path"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(
                    validationContext
                  )}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)})],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }