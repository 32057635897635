<template>
  <b-card>
    <!-- Table Top -->
    <b-row>
      <b-col
        cols="12"
        md="12"
        class="text-right"
      >
        <b-button
          variant="primary"
          @click="showAddClient()"
        >
          <feather-icon
            icon="PlusIcon"
            size="16"
          />
          <span class="text-nowrap">Add Organisation</span>
        </b-button>
      </b-col>
    </b-row>

    <clients-list ref="clientList" />

    <add-client
      ref="addClient"
      @clientAdded="onClientAdded"
    />
  </b-card>
</template>

<script>
import { BRow, BCol, BButton, BCard } from "bootstrap-vue";
import ClientsList from "./ClientsList.vue";
import AddClient from "./AddClient.vue";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    ClientsList,
    AddClient,
  },
  methods: {
    showAddClient() {
      this.$refs.addClient.show();
    },
    onClientAdded() {
      this.$refs.clientList.loadItems();
    },
  },
};
</script>
