var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vue-good-table',{staticClass:"mt-3",attrs:{"mode":"remote","is-loading":_vm.isLoading,"columns":_vm.columns,"rows":_vm.rows,"rtl":_vm.isRtl,"search-options":{
    enabled: false,
  },"pagination-options":{
    enabled: true,
    perPage: _vm.perPage,
  },"style-class":"vgt-table striped"},on:{"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'name')?_c('div',{staticClass:"justify-content-between flex-wrap align-items-start",attrs:{"cols":"12","md":"2"}},[_c('div',{staticClass:"align-items-start mb-0 mt-0"},[_c('router-link',{staticClass:"table-primary-link",attrs:{"to":{
            name: 'admin-client',
            params: { clientId: props.row.id },
          }}},[_vm._v(" "+_vm._s(props.row.name)+" ")])],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('table-pagination',{attrs:{"per-page":_vm.perPage,"total":_vm.total},on:{"perPageChanged":function (value) { return props.perPageChanged({ currentPerPage: value }); },"pageChanged":function (value) { return props.pageChanged({ currentPage: value }); }}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }