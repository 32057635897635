<template>
  <vue-good-table
    class="mt-3"
    mode="remote"
    :is-loading="isLoading"
    :columns="columns"
    :rows="rows"
    :rtl="isRtl"
    :search-options="{
      enabled: false,
    }"
    :pagination-options="{
      enabled: true,
      perPage: perPage,
    }"
    style-class="vgt-table striped" 
    @on-sort-change="onSortChange"
    @on-column-filter="onColumnFilter"
    @on-per-page-change="onPerPageChange"
  >
    <template
      slot="table-row"
      slot-scope="props"
    >
      <!-- Column: Action -->
      <div
        v-if="props.column.field === 'name'"
        cols="12"
        md="2"
        class="justify-content-between flex-wrap align-items-start"
      >
        <div class="align-items-start mb-0 mt-0">
          <router-link
            :to="{
              name: 'admin-client',
              params: { clientId: props.row.id },
            }"
            class="table-primary-link"
          >
            {{ props.row.name }}
          </router-link>
        </div>
      </div>

      <!-- Column: Common -->
      <span v-else>
        {{ props.formattedRow[props.column.field] }}
      </span>
    </template>

    <!-- pagination -->
    <template
      slot="pagination-bottom"
      slot-scope="props"
    >
      <table-pagination
        :per-page="perPage"
        :total="total"
        @perPageChanged="
          (value) => props.perPageChanged({ currentPerPage: value })
        "
        @pageChanged="
          (value) => props.pageChanged({ currentPage: value })
        "
      />
    </template>
  </vue-good-table>
</template>

<script>
import { 
  GOODTABLE_ISO_DATETIME_INPUT_FORMAT, 
  GOODTABLE_LOCALISED_DATE_FORMAT,
  GOODTABLE_SEARCH_FIELD_DATE_PICKER_FORMAT, 
  PRIMARY_DATE_FORMAT,
  localDateStringOrDateRangeStringToUtcRange 
} from "@/libs/utils";
import TablePagination from "@/views/components/pagination/TablePagination.vue";

import flatPickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/themes/material_blue.css";
import { VueGoodTable } from "vue-good-table";
import store from "@/store";
import clientsService from "@/services/clientsService";
import useAomTableConfig from "@aom-core/useAomTableConfig.js";
export default {
  components: {
    VueGoodTable,
    TablePagination,
  },
  data() {
    return {
      isLoading: false,
      modalShow: false,
      columns: [
        {
          label: "Name",
          field: "name",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Date Created",
          field: "created_at",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
            filterFn: this.dateFilter
          },
          thClass: 'vgt-left-align',
          tdClass: "search-date-selector",
          width: "12em",
          type: 'date',
          dateInputFormat: GOODTABLE_ISO_DATETIME_INPUT_FORMAT,
          dateOutputFormat: GOODTABLE_LOCALISED_DATE_FORMAT
        },
        {
          label: "Programs",
          field: "programs_count",
          filterOptions: {
            enabled: true,
            placeholder: "Search Programs",
          },
          tdClass: "text-center",
          width: "14em",
        },
        {
          label: "Active Programs",
          field: "active_programs_count",
          filterOptions: {
            enabled: true,
            placeholder: "Search Programs",
          },
          tdClass: "text-center",
          width: "14em",
        }
      ],
      rows: [],
      columnFilters: [],
      sort: [],
    };
  },
  computed: {
    isRtl() {
      return store.state.appConfig.isRTL;
    },
  },
  created() {
    this.loadItems();
  },
  mounted() {
    const inputs = document.getElementsByName("vgt-created_at");
    inputs.forEach(function (input) {
      flatPickr(input, {
        enableTime: false,
        dateFormat: GOODTABLE_SEARCH_FIELD_DATE_PICKER_FORMAT, 
        allowInput: true,
        altInput: true,
        altFormat: PRIMARY_DATE_FORMAT,
        mode: "range",
      });
    });
  },
  methods: {
    dateFilter(date, filterString) {
      return (date =
            Date.parse(date) >= Date.parse(filterString));
    },
    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },

    onPerPageChange(params) {
      this.perPage = Number(params.currentPerPage);
      this.loadItems();
    },

    onSortChange(params) {
      let field = params[0].field;
      let type = params[0].type;
      if (type !== 'none') {
        this.sort = [{ field: field, type: type }];
      } else {
        this.sort = [];
      }
      this.loadItems();
    },

    onColumnFilter(params) {
      const columnFilters = [];
      for (let col of [
        "name",
        "created_at",
        "programs_count",
        "active_programs_count",
      ]) {
        if (params.columnFilters[col]) {
          if (col === "created_at") {
            const dateRange = localDateStringOrDateRangeStringToUtcRange(params.columnFilters[col]);
            if (dateRange) {
              columnFilters.push({
                field: col,
                value: dateRange,
              });
            }
          } else {
            columnFilters.push({
              field: col,
              value: params.columnFilters[col],
            });
          }
        }
      }
      this.columnFilters = columnFilters;
      this.loadItems();
    },

    async loadItems() {
      try {
        this.isLoading = true;
        const response = await clientsService.getList({
          page: this.page,
          perPage: this.perPage,
          columnFilters: this.columnFilters,
          sort: this.sort,
        });
        this.total = response.data.total;
        this.rows = response.data.items;
      } catch (e) {
        this.$log.error(e);
      }
      this.isLoading = false;
    }
  },
  setup() {
    const { total, perPage, page } = useAomTableConfig();
    return {
      total,
      perPage,
      page
    };
  }
};
</script>

<style>
  .search-date-selector{
    text-align: center !important;
  }
</style>

<style lang="scss" >
@import "/src/assets/scss/vue-good-table.scss";
</style>
